import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  connect() {
    let toastElList = [].slice.call(document.querySelectorAll('.toast'));
    let toastList = toastElList.map(function (toastEl) {
      const option = {
        autohide: true,
      };
      return new bootstrap.Toast(toastEl, option);
    });
    toastList.forEach((toast) => toast.show());
    $('#spinner-for-submit').hide();
  }
}
