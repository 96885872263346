import { Controller } from "@hotwired/stimulus"
import autosize from 'autosize'

// Connects to data-controller="autosize"
export default class extends Controller {
  static targets = ['customQuery']
  connect() {
    autosize($('textarea'));
  }

  copyToClipboard(e){
    e.preventDefault();
    console.log(this.customQueryTarget.innerHTML)

    this.customQueryTarget.select()

    this.customQueryTarget.setSelectionRange(0, 99999);
    document.execCommand('copy');
    alert('>>> 클립보드로 다음의 내용이 복사되었습니다.\n--------------------------------------\n' + this.customQueryTarget.innerHTML)
  }
}
