import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ['entries', 'pagination', 'bottom', 'eof'];

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    // console.log(this.element);

    this.intersectionObserver.observe(this.paginationTarget)

    const placeholders = document.querySelectorAll('.placeholder');

    placeholders.forEach((placeholder) => {
      const small = placeholder.querySelector('.img-small');

      // 1: load small image and show it
      const img = new Image();
      img.src = small.src;
      img.onload = function () {
        small.classList.add('loaded');
      };

      // 2: load large image
      const imgLarge = new Image();
      imgLarge.src = placeholder.dataset.large;
      imgLarge.onload = function () {
        imgLarge.classList.add('loaded');
      };
      const a_link = placeholder.querySelector('a');
      a_link.appendChild(imgLarge);
    });

    let topButton = document.querySelector('.totop-btn');
    topButton.addEventListener('click', () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });

    document.addEventListener('scroll', (e) => {
      if (document.documentElement.scrollTop <= 150) {
        topButton.classList.add('d-none');
      } else {
        topButton.classList.remove('d-none');
      }
    });
  }

  disconnect(){
    this.intersectionObserver.unobserve(this.paginationTarget);
  }

  processIntersectionEntries(entries){
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    // console.log(next_page)

    if (next_page === null) {
      this.bottomTarget.classList.add('d-none');
      this.eofTarget.classList.remove('d-none');
      return;
    } else {
      this.bottomTarget.classList.remove('d-none');
      // this.bottomTarget.htmlContent = `<div class='alert alert-warning'>Data loading...</div>`;
    }

    let url = next_page.href;

    $.get(url, (data, status) => {
      let pagedCollection = document.createElement('div');
      pagedCollection.innerHTML = data.entries;

      const placeholders = pagedCollection.querySelectorAll('.placeholder');

      // console.log(pagedCollection.children);

      placeholders.forEach((placeholder) => {
        const small = placeholder.querySelector('.img-small');

        // 1: load small image and show it
        const img = new Image();
        img.src = small.src;
        img.onload = function () {
          small.classList.add('loaded');
        };

        // 2: load large image
        const imgLarge = new Image();
        imgLarge.src = placeholder.dataset.large;
        imgLarge.onload = function () {
          imgLarge.classList.add('loaded');
        };
        const a_link = placeholder.querySelector('a');
        a_link.appendChild(imgLarge);
      });

      // this.entriesTarget.append(pagedCollection.children);
      [...pagedCollection.children].forEach((card) => {
        // console.log(card);
        this.entriesTarget.append(card);
      });

      // this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
      this.paginationTarget.innerHTML = data.pagination;
    });
  }
}
