import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fileupload"
export default class extends Controller {
  static targets = ["vimeoFile"]

  connect() {
    // console.log("Fileupload Controller connected...")
  }

  handleCheckFileSize(event) {
    const $uploaded = $(this.vimeoFileTarget);
    const uploaded_file = $uploaded[0].files[0]
    const file_mb_size = (uploaded_file.size / 1024 / 1024).toFixed(1);
    const locale = $("#i18n_locale").data('locale');
    if(uploaded_file.size > 209_715_200) {
      console.warn(`업로드 파일크기: ${file_mb_size}MB`)
      alert(`선택한 파일크기(${file_mb_size}MB)는 200MB를 초과할 수 없습니다.`);
      $uploaded[0].value = null;
      $uploaded.prev()[0].innerHTML = `${locale === 'ko' ? '강의 동영상' : 'Video'}`
    } else {
      $uploaded.prev()[0].innerHTML = `${locale === 'ko' ? '강의 동영상  (✅ 파일크기:' : 'Video  (✅ File size:'} ${file_mb_size}MB)`
    }
  }
}
