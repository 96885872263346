import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mailing"
export default class extends Controller {
  static targets = [ 'immediateSend' ]

  connect() {
    // console.log(this.element)
    if(this.hasImmediateSendTarget && this.immediateSendTarget.checked){
      this.sendMail(true)
    }
  }

  sendMail(bool) {
    if(bool) {
      $("button[type=submit] .show-when-enabled .fa5-text").text("저장후 요청메일 보내기");
      $("button[type=submit] .show-when-disabled .fa5-text").text("저장후 요청메일 발송 중...");
      $('button[type=submit]').removeClass('btn-primary').addClass('btn-success');
      $("#letter_reserved_at").attr('disabled', false)
    } else {
      $("button[type=submit] .show-when-enabled .fa5-text").text("저장");
      $("button[type=submit] .show-when-disabled .fa5-text").text("저장 중...");
      $("button[type=submit]").removeClass('btn-success').addClass('btn-primary')
      $("#letter_reserved_at").val('')
      $("#letter_reserved_at").attr('disabled', true)
    }
  }

  handleSendMail(event) {
    const mailing = event.target.checked;
    console.log(mailing)
    this.sendMail(mailing)
  }

  handleReset(event){
    console.log(event.target.value)
    // $("#letter_immediate_send").click();
    if(event.target.value === 'Reset'){
      this.sendMail(false)
    }
  }

}
