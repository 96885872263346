import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="lecture"
export default class extends Controller {
  // static values = {
  //   newRecord: Boolean,
  // };
  static targets = [
    'legacyCat',
    'catLocation',
    'catCommon',
    'catOrgan',
    'catModality',
    'newCategory',
    'category1',
    'category2',
    'category3',
    'category4',
  ];

  connect() {
    const cats = [
      this.category1Target,
      this.category2Target,
      this.category3Target,
      this.category4Target,
    ];

    if (this.hasLegacyCatTarget && this.legacyCatTarget.value) {
      // console.log(this.legacyCatTarget.value);
      this.changeCategory(this.legacyCatTarget.value);
    }

    const elearningCats = this.newCategoryTarget.value.split(',');
    // console.log(elearningCats);
    elearningCats.forEach((cat_id, index) => {
      console.log(index, cat_id);
      let fetch_url = `/categories.json?id=${cat_id}`;
      fetch(fetch_url)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          data.forEach((category) => {
            $(cats[index]).append(
              `<option value='${category.id}' ${
                category.id == parseInt(cat_id) ? 'selected' : ''
              }>${category.name}</option>`
            );
          });
        });
    });

    this.changeDisplay(this.legacyCatTarget.value);
    // this.newCategoryTarget.style.display = 'none';
  }

  initCats() {
    this.catLocationTarget.value = '';
    this.catCommonTarget.value = '';
    this.catOrganTarget.value = '';
    this.catModalityTarget.value = '';

    $(this.catLocationTarget).parent().find('.checkbox-group').remove();
    $(this.catCommonTarget).parent().find('.checkbox-group').remove();
    $(this.catOrganTarget).parent().find('.checkbox-group').remove();
    $(this.catModalityTarget).parent().find('.checkbox-group').remove();
  }

  changeDisplay(cat_id) {
    if (cat_id == '') {
      this.catLocationTarget.style.display = 'block';
      this.catCommonTarget.style.display = 'block';
      this.catOrganTarget.style.display = 'block';
      this.catModalityTarget.style.display = 'block';
    } else {
      this.catLocationTarget.style.display = 'none';
      this.catCommonTarget.style.display = 'none';
      this.catOrganTarget.style.display = 'none';
      this.catModalityTarget.style.display = 'none';
    }
  }

  handleChangeCategory(e) {
    // console.log('called handleChangeCategory method');
    const cat_id = e.target.value;
    this.initCats();
    this.changeDisplay(cat_id);
    if (cat_id != '') {
      this.changeCategory(e.target.value);
    }
  }

  changeCategory(id) {
    fetch(`/legacy_categories.json?id=${id}/`)
      .then((response) => response.json())
      .then((dataForLegacyCat) => {
        // console.log(dataForLegacyCat);
        const category_objs = [
          this.catLocationTarget,
          this.catCommonTarget,
          this.catOrganTarget,
          this.catModalityTarget,
        ];
        dataForLegacyCat.forEach((category, index) => {
          fetch(`/legacy_categories.json?id=${category.id}`)
            .then((response) => response.json())
            .then((dataForMajorCat) => {
              // $(category_objs[index]).parent().find('.form-check').remove();
              $(category_objs[index])
                .parent()
                .append(
                  "<div class='checkbox-group border border-1 rounded px-2 py-1 mt-1'></div>"
                );
              dataForMajorCat.forEach((cat) => {
                $(category_objs[index])
                  .next()
                  .append(
                    `<div class='form-check form-check-inline'>
                    <input type='checkbox' value='${cat.id}' id='chck-cat-${
                      cat.id
                    }' class='form-check-input' data-action='check-category#handleCheckCategory' ${
                      category_objs[index].value
                        .split(',')
                        .map((item) => parseInt(item, 10))
                        .includes(cat.id)
                        ? 'checked'
                        : ''
                    }>
                    <label class='form-check-label' for='chck-cat-${cat.id}'>${
                      cat.name
                    }</label>
                  </div>`
                  );
              });
            });
        });
      });
  }
}
