import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="check-category"
export default class extends Controller {
  connect() {
    // console.log(this.element);
    $('.checkbox-group').remove();
  }

  handleCheckCategory(e) {
    const $current_legacy_category = $("input[name=legacy_category_id]:checked")
    // console.log($current_legacy_category.val(), e.target.value)
    // console.log(e.target.id)
    let temp = $(e.target).closest('.field').find('input[type=text]')[0].id.split('_')
    temp.shift()
    // console.log(temp.join('_'))
    const $target_category = $(`#legacy_category_${$current_legacy_category.val()} input[name*='${temp.join('_')}']`)
    // console.log($target_category.val())

    let values = $target_category.val().split(',').filter((e) => e)

    if (e.target.checked) {
      values.push(e.target.value);
    } else {
      const index = values.indexOf(e.target.value);
      if (index !== -1) {
        values.splice(index, 1);
      }
    }
    $target_category.val(values.sort().join(','));
  }

  handleCheckCategoryFilter(e) {
    // console.log(e.target)
    const $text_input = $(e.target).closest('.field').find('input[type=text]');
    // $text_input.text(e.target.value)
    let values = $text_input.val().split(',').filter((e) => e)
    // console.log(values)
    values.push(e.target.value)
    $text_input.val(values.join(","))
    // console.log(values)
    // console.log($text_input.val())
    // console.log($(e.target).closest('.field').find('input[type=text]').text().split(','))
  }
}
