import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="role"
export default class extends Controller {
  static values = {
    roleTarget: String
  }
  async handleFilterByRole(event){
    const role = event.target.value;
    // const query_keys = location.href.split("?")[1].split("&").filter(query => query.split("=")[0] !== 'role')
    let query_keys = location.href.split("?").splice(1)
    if(query_keys.length == 1){
      query_keys = query_keys[0].split("&").filter(query => query.split("=")[0] !== 'role')
    }

    const url = (role === 'all' ? `/admin/${this.roleTargetValue}` : `/admin/${this.roleTargetValue}?${query_keys.concat([`role=${role}`]).join('&')}`)
    // console.log(url)

    $('#spinner-for-submit').show();
    const request = new FetchRequest('get', url, { responseKind: "turbo-stream" })
    const response = await request.perform()
    if (response.ok) {
      $('#spinner-for-submit').fadeOut('slow');
    }
  }
}
