import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="accordion"
export default class extends Controller {
  connect() {
    // console.log(this.element.children);
    $.each(this.element.children, (index, accordion) => {
      // $(accordion).data('aria-expanded', true);
      if (index === 0) {
        $(accordion).find('button').trigger('click');
        // $(accordion).find('button')[0].ariaExpanded = true;
        // $(accordion).find('.accordion-collapse').addClass('show');
      }
      // console.log($(accordion).find('button')[0].ariaExpanded);
    });
  }
}
