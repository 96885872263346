import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="lecture"
export default class extends Controller {
  static values = {
    newRecord: Boolean,
    publishLocationCd: String,
    firstLegacyCategoryId: Number,
  }
  static targets = [
    'legacyCat',
    'catLocation',
    'catCommon',
    'catOrgan',
    'catModality',
  ];


  connect() {
    $('#spinner-for-submit').fadeOut();
    if (this.hasLegacyCatTarget && this.legacyCatTarget.value) {
      // console.log(this.legacyCatTarget.value);
      // this.changeCategory(this.legacyCatTarget.value);
    }

    if (this.hasLegacyCatTarget) {
      this.changeDisplay(this.legacyCatTarget.value);
    }

    if (this.hasPublicLocationCdValue) {
      this.checkLocation(this.publishLocationCdValue);
    }

    if (this.hasFirstLegacyCategoryIdValue) {
      console.log($(`#legacy_category_id_${this.firstLegacyCategoryIdValue}`));
      $(`#legacy_category_id_${this.firstLegacyCategoryIdValue}`)[0].click();
    }
  }

  initCats() {
    // console.log("initCats() called...")
    this.catLocationTarget.value = '';
    this.catCommonTarget.value = '';
    this.catOrganTarget.value = '';
    this.catModalityTarget.value = '';

    $(this.catLocationTarget).parent().find('.checkbox-group').remove();
    $(this.catCommonTarget).parent().find('.checkbox-group').remove();
    $(this.catOrganTarget).parent().find('.checkbox-group').remove();
    $(this.catModalityTarget).parent().find('.checkbox-group').remove();
  }

  changeDisplay(cat_id) {
    if (cat_id === '') {
      this.catLocationTarget.style.display = 'block';
      this.catCommonTarget.style.display = 'block';
      this.catOrganTarget.style.display = 'block';
      this.catModalityTarget.style.display = 'block';
    } else {
      this.catLocationTarget.style.display = 'none';
      this.catCommonTarget.style.display = 'none';
      this.catOrganTarget.style.display = 'none';
      this.catModalityTarget.style.display = 'none';
    }
  }

  handleClickSelect(e){
    let $select = $(e.target).closest('tr').find('select')[0];
    let $selected_text = $select.options[$select.selectedIndex].text
    // $("#category-panel").slideDown();
    this.initCats();
    $("#category-panel .card-header").text('data loading...')
    this.changeCategory($select.value, () => $("#category-panel").slideUp('fast').delay(100).slideDown('fast', () => {
      $("#category-panel .card-header").text($selected_text).hide().fadeIn();
    }));
    $("#hide-category-panel").fadeIn();
  }

  handleAddLegacyCategoryFields(e){
    e.preventDefault();

    const link = $(e.currentTarget)[0];

    let time = new Date().getTime();
    // console.log('time', time)
    // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
    let linkId = link.dataset.id;
    // console.log('linkId', linkId)
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
    let regexp = linkId ? new RegExp(linkId, 'g') : null;
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
    // console.log(newFields)
    // Add the new markup to the form if there are fields to add.
    // newFields ? link.insertAdjacentHTML("beforebegin", newFields) : null;

    $('#legacy_category_rows').append(newFields);
  }

  handleAddNewCategoryFields(e){
    e.preventDefault();

    const link = $(e.currentTarget)[0];

    let time = new Date().getTime();
    // console.log('time', time)
    // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
    let linkId = link.dataset.id;
    // console.log('linkId', linkId)
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
    let regexp = linkId ? new RegExp(linkId, "g") : null;
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
    // console.log(newFields)
    // Add the new markup to the form if there are fields to add.
    // newFields ? link.insertAdjacentHTML("beforebegin", newFields) : null;

    $("#category_rows").append(newFields)
  }

  handleInitSelect(e){
    e.preventDefault();
    $("input[name=legacy_category_id]:checked")[0].checked = false
    $("#category-panel").slideUp();
    $("#hide-category-panel").fadeOut();
  }

  handleChangeCategory(e) {
    const selected_radio_button_id = $(e.target).closest('tr')[0].id.split('_').pop();
    // console.log(selected_radio_button_id)
    $(`#legacy_category_id_${selected_radio_button_id}`)[0].checked = true;
    $("#hide-category-panel").fadeIn();
    // $("#category-panel").slideDown();

    let $selected_text = e.target.options[e.target.selectedIndex].text
    // let $selected_index = e.target.selectedIndex
    // console.log('called handleChangeCategory method');
    const cat_id = e.target.value;
    this.initCats();
    this.changeDisplay(cat_id)

    // console.log($(e.target).closest('tr')[0].id);
    $(e.target).closest('tr')[0].id = `legacy_category_${cat_id}`
    $(e.target).closest('tr').find('input[type=radio]')[0].id = `legacy_category_id_${cat_id}`
    $(e.target).closest('tr').find('input[type=radio]')[0].value = cat_id
    $(e.target).closest('tr').find('input[type=text]')[0].value = ''
    $(e.target).closest('tr').find('input[type=text]')[1].value = ''
    $(e.target).closest('tr').find('input[type=text]')[2].value = ''
    $(e.target).closest('tr').find('input[type=text]')[3].value = ''

    // console.log($(e.target).closest('tr')[0].id);

    if (cat_id !== "") {
    // if (true) {
      // console.log($(`#legacy_category_${cat_id} td input[type=text]`));
      // $("#category-panel .card-header").text($selected_text)
      // this.changeCategory(e.target.value);
      $("#category-panel .card-header").text('data loading...')
      this.changeCategory(cat_id, () => $("#category-panel").slideUp('fast').delay(100).slideDown('fast', () => {
        $("#category-panel .card-header").text($selected_text).hide().fadeIn();
      }));
    }
  }

  handleSubmit(_event){
    // event.preventDefault();
    $('.never-submit').prop('disabled', true);
    // event.target.submit();
  }

  changeCategory(id, callback) {
    // const selected_value = id;
    // const selected_option_values = this.selected_values();
    // console.log(selected_option_values, selected_value);

    this.checkSelectedState()

    if(id) {
      fetch(`/admin/legacy_categories/lookup_list.json?id=${id}/`)
        .then((response) => response.json())
        .then((dataForLegacyCat) => {
          // console.log(dataForLegacyCat);
          const category_objs = [
            this.catLocationTarget,
            this.catCommonTarget,
            this.catOrganTarget,
            this.catModalityTarget,
          ];
          const category_values = $(
            `#legacy_category_${id} td input[type=text]`
          );
          // console.log(category_values);

          dataForLegacyCat.forEach((category, index) => {
            fetch(`/admin/legacy_categories/lookup_list.json?id=${category.id}`)
              .then((response) => response.json())
              .then((dataForMajorCat) => {
                // $(category_objs[index]).parent().find('.form-check').remove();
                $(category_objs[index])
                  .parent()
                  .append(
                    "<div class='checkbox-group border border-1 rounded px-2 py-1 mt-1 bg-white'></div>"
                  );
                // console.log("draw group")
                dataForMajorCat.forEach((cat) => {
                  $(category_objs[index])
                    .next()
                    .append(
                      `<div class='form-check form-check-inline'>
                    <input type='checkbox' value='${cat.id}' id='chck-cat-${
                        cat.id
                      }' class='form-check-input' data-action='check-category#handleCheckCategory' ${
                        category_values[index].value
                          .split(',')
                          .map((item) => parseInt(item, 10))
                          .includes(cat.id)
                          ? 'checked'
                          : ''
                      }>
                    <label class='form-check-label' for='chck-cat-${cat.id}'>${
                        cat.name
                      }</label>
                  </div>`
                    );
                });
              });
          });
        })
        .then(() => {
          if (typeof callback == 'function') {
            callback();
          }
        });
    } else {
      $("#category-panel").slideUp();
    }
  }

  checkLocation(location) {
    // console.log('publish location:', location)
    if (location === '2') {
      $('#lecture_charge_cd_0')[0].checked = true;
      $('#col-for-charge-cd').hide();
      $('.actions').css('margin-top', '1rem');
    } else {
      $('#col-for-charge-cd').show();
      $('.actions').css('margin-top', '2rem')
    }
  }

  handleCheckLocation(e) {
    const location = e.target.value;
    if (location === '2') {
      alert('동영상열람대상이 "전체회원용"으로 변경됩니다.')
      $('#col-for-charge-cd').slideUp('fast');
    } else {
      $('#col-for-charge-cd').slideDown('fast');
    }
    this.checkLocation(location)
  }

  selected_values() {
    const $selects = $("tr[id^=legacy_category_] .form-select");
    let selected_option_values = [];
    $.each($selects, (index, select) => {
      if (select.selectedIndex !== 0) {
        selected_option_values.push(select.selectedIndex);
      }
    });
    return selected_option_values.sort();
  }

  checkSelectedState() {
    // .form-select ⇢ side effect ⇢ .legacy-category-select
    $.each($('.legacy-category-select'), (index, element) => {
      $.each($(element).find('option'), (index, option) => {
        // console.info(option.selected);
        if (this.selected_values().indexOf(index) !== -1 && !option.selected) {
          $(option).attr('disabled', true);
        } else {
          $(option).attr('disabled', false);
        }
      });
    });
  }
}
