import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="register-role"
export default class extends Controller {
  static targets = ['user', 'userAllChecked', 'withEmail', 'role']
  static values = {
    resources: String
  }

  connect() {
    console.log('Register Role Controller connected...', this.element)
    console.log(this.resourcesValue)
  }

  registerAll(e){
    // e.preventDefault()
    const userAllChecks = this.userTargets
    // console.log(this.userAllCheckedTarget.checked)
    userAllChecks.forEach(user => {
      user.checked = !user.checked
    })
  }

  register(e) {
    e.preventDefault();

    const with_email_notified = this.withEmailTarget.checked
    const role = this.roleTarget.value
    const resources = this.resourcesValue
    const userChecks = this.userTargets.filter(user => user.checked)
    const ids = userChecks.map(user => user.value).join(',')
    if(userChecks.length == 0) {
      alert("등록할 사용자를 선택한 후 계속하세요.")
    } else {
      if (confirm(`${role} 위촉을 계속하시겠습니까?`) == true) {
        let formData = new FormData();
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        formData.append('authenticity_token', csrfToken)
        formData.append("ids", ids);
        formData.append('email', with_email_notified);
        formData.append('role', role)

        let request = new XMLHttpRequest();
        request.open("POST", `/admin/${resources}/register_role`);
        request.send(formData);
        alert(`${role} 위촉이 완료되었습니다.`)
        location.href = `/admin/dmzs?role=${role}`
      }
    }
  }

  unregister(e) {
    e.preventDefault();

    const with_email_notified = this.withEmailTarget.checked
    const role = this.roleTarget.value
    const resources = this.resourcesValue
    const userChecks = this.userTargets.filter(user => user.checked)
    if(userChecks.length == 0) {
      alert("해제할 사용자를 선택한 후 계속하세요.")
    } else {
      if (confirm(`${role} 해촉을 계속하시겠습니까?`) == true) {
        let formData = new FormData();
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        formData.append('authenticity_token', csrfToken)
        formData.append("ids", userChecks.map(user => user.value));
        formData.append('email', with_email_notified)
        formData.append('role', role)
        let request = new XMLHttpRequest();
        request.open("POST", `/admin/${resources}/unregister_role`);
        request.send(formData);
        alert(`${role} 해촉이 완료되었습니다.`)
        location.href = `/admin/${resources}?role=${role}`
      }
    }
  }
}
