import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gotop"
export default class extends Controller {
  connect() {
    //Get the button
    // const goTopBtn = document.getElementById("goTopBtn");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = () => {
      this.scrollFunction()
    };
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.element.style.display = "block";
    } else {
      this.element.style.display = "none";
    }
  }
}

