import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="legacy_category"
export default class extends Controller {
  static values = {
    firstChildrenId: String,
    secondChildrenId: String,
    firstLabel: String,
    secondLabel: String,
  };

  handleSelectChange(e) {
    $(this.firstChildrenIdValue)
      .empty()
      .append(`<option value="">${this.firstLabelValue}분류</option>`);
    if (this.hasSecondChildrenIdValue) {
      $(this.secondChildrenIdValue)
        .empty()
        .append(`<option value="">${this.secondLabelValue}분류</option>`);
    }

    let fetch_url;
    if (e.target.value == '') {
      fetch_url = '/admin/legacy_categories/lookup.json';
      $.get(`/admin/legacy_categories.js`);
    } else {
      fetch_url = `/admin/legacy_categories/${e.target.value}/children.json`;
      // $.get(`/legacy_categories/${e.target.value}/children.js`);
    }
    fetch(fetch_url)
      .then((response) => response.json())
      .then((data) => {
        data.forEach((legacy_category) => {
          $(this.firstChildrenIdValue).append(
            `<option value="${legacy_category.id}">${legacy_category.name}</option>`
          );
        });
      });
  }
}
