import { Controller } from "@hotwired/stimulus"
import Player from '@vimeo/player'

// Connects to data-controller="view-counter"
export default class extends Controller {
  static targets = ['iframe']
  static values = {
    id: Number,
    hit: Number
  }

  connect() {
    // console.log(`Title of Lecture: ${this.iframeTarget.title}`);
    // console.log(`View Count: ${this.hitValue}`);

    const iframe = this.iframeTarget;
    const player = new Player(iframe);

    player.on('play', async () => {
      // console.log(`Played the video for Lecture ID #${this.idValue}`);
      await fetch(`/lectures/${this.idValue}/hit.json`)
        .then(response => response.json())
        .then(lecture => {
          const already_hit = this.hitValue == parseInt(lecture['view_count'])
          const hit_info = already_hit ? "(Today, you are already hit this lecture)" : "(🎉 Today, you've clicked this video for the first time)"
          $(`#hit_count_of_lecture_${this.idValue}`).text(lecture['view_count']);
          if (!already_hit) {
            $(`#hit_count_of_lecture_${this.idValue}`).effect('highlight', {}, 3000);
          }
          // console.log(`View Count: ${this.hitValue} -> ${lecture['view_count']}`);
        })
    });
  }
}
