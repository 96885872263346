import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="data-link"
export default class extends Controller {
  connect() {
    // console.log(this.element);
  }

  handleClickToNav(e) {
    const $row = $(e.target).parent();
    if (e.target.tagName === 'TD') {
      location.href = $row.data('link');
    }
  }
}
