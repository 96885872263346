import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['toBeCopied']
  connect() {

  }

  copy(e){
    e.preventDefault();
    const textToCopy = this.toBeCopiedTarget.innerText;
    console.log(textToCopy)

    const myTemporaryInputElement = document.createElement("input");
    myTemporaryInputElement.type = "text";
    myTemporaryInputElement.value = textToCopy;

    document.body.appendChild(myTemporaryInputElement);

    myTemporaryInputElement.select();
    document.execCommand("Copy");
    $(this.element).effect('highlight', {}, 'yellow');

    document.body.removeChild(myTemporaryInputElement);
  }
}
