import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="video"
export default class extends Controller {
  static values = {
    url: String,
    poster: String,
    width: Number,
    height: Number,
    alias: String,
    vimeoId: String,
    loaded: Boolean,
    key: String,
    lectureId: Number,
  };

  connect() {}

  popUpVimeo(e) {
    e.preventDefault();

    if ($(e.target).parent().find('iframe')[0] === undefined) {
      $(e.target)
        .parent()
        .prepend(
          `
        <div>
          <iframe style="border:1px solid black;"
            src="https://player.vimeo.com/video/${this.vimeoIdValue}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
            width="250"
            height="142"
            allowfullscreen
            allowtransparency
            allow="autoplay"
          ></iframe>
        </div>
      `
        )
        .hide()
        .slideDown();
    } else {
      $(e.target)
        .prev()
        .slideUp('normal', function () {
          $(this).remove();
        });
    }
  }
}
