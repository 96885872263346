import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    url: String,
    enabled: Boolean,
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      ghostClass: 'sortable-ghost',
      disabled: !this.enabledValue,
      onEnd: this.end.bind(this),
    });
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    console.log(event.newIndex)
    data.append("position", event.newIndex + 1)

    fetch(this.urlValue.replace(":id", id), {
      method: 'PATCH',
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
      body: data
    })
      .then(data => console.log(data))
  }
}