import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static targets = [ "form", 'role', 'search' ]

  async search(e) {
    clearTimeout(this.timeout)
    $('#spinner-for-submit').show();
    this.timeout = setTimeout(async () => {
      // this.formTarget.requestSubmit()
      const request = new FetchRequest('get', `${this.formTarget.action}?role=${this.roleTarget.value}&q=${this.searchTarget.value}`, { responseKind: "turbo-stream" })
      const response = await request.perform()
      if (response.ok) {
        $('#spinner-for-submit').fadeOut('slow');
      }
    }, 200)
  }
}
