import '@hotwired/turbo-rails';
import "@rails/request.js";
import './controllers';
import 'trix';
import '@rails/actiontext';
import './src/jquery';
import './src/jquery-ui.js';
import '@fortawesome/fontawesome-free/js/all';
import { switchTheme } from './utils';

$(document).on('turbo:load', () => {
  // console.log('⎈Turbo.js loaded...', new Date());
  $("#lecture-filter").on("click", function (e) {
    e.preventDefault();
    $("#category-filter-panel").slideToggle();
  });

  $("#spinner").hide();
  $("#spinner-for-submit").hide();

  $("#sidebar").on("hidden.bs.collapse", (e) => {
    $(
      "a[data-bs-target='#sidebar']"
    )[0].innerHTML = `<i class="fas fa-angle-right"></i> ${$(
      "a[data-bs-target='#sidebar']"
    ).data("link-label-open")}`;
    $("#lecture-cards > div").removeClass("col-md-4").addClass("col-md-3");
  });

  $("#sidebar").on("shown.bs.collapse", (e) => {
    $(
      "a[data-bs-target='#sidebar']"
    )[0].innerHTML = `<i class="fas fa-angle-left"></i> ${$(
      "a[data-bs-target='#sidebar']"
    ).data("link-label-close")}`;
    $("#lecture-cards > div").removeClass("col-md-3").addClass("col-md-4");
  });

  switchTheme(".theme-switch");
});

// turbo:click fires when you click a Turbo-enabled link.
$(document).on('turbo:click', function () {
  // console.log('turbo:click event fired...');
  $('#spinner').fadeIn();
});

// turbo:render fires after Turbo renders the page.
$(document).on('turbo:render', function () {
  // console.log('turbo:render event fired...');
  $('#spinner').fadeOut();
});

// turbo:frame-load fires when <turbo-frame> element is navigated and finishes loading (fires after turbo:frame-render)
$(document).on('turbo:frame-load', function () {
  // console.log('turbo:click event fired...');
  $('#spinner').fadeOut();
});

// turbo:frame-render fires right after <turbo-frame> element renders its view.
$(document).on('turbo:frame-render', function () {
  // console.log('turbo:render event fired...');
  $('#spinner').fadeOut();
});

// turbo:submit-start fires during a form submission.
$(document).on('turbo:submit-start', function () {
  // console.log('forced to fade out #spinner before turbo:submit-start event to be fired...');
  $('#spinner').fadeOut();
  // console.log('turbo:submit-start event fired...');
  $('#spinner-for-submit').fadeIn();
});

// turbo:submit-end fires after the form submission-initiated network request completes.
$(document).on('turbo:submit-end', function () {
  // console.log('turbo:submit-end event fired...');
  $('#spinner-for-submit').fadeOut();
});