import { Controller } from "@hotwired/stimulus"
import Masonry from "masonry-layout"
import imagesLoaded from 'imagesloaded';

// Connects to data-controller="masonry"
export default class extends Controller {
  connect() {
    const msnry = new Masonry( '.grid', {
      columnWidth: '.grid-sizer',
      gutter: '.gutter-sizer',
      itemSelector: '.grid-item',
      percentPosition: true
    })

    const grid_items = document.querySelectorAll('.grid-item');
    imagesLoaded( grid_items, function() {
      // msnry.masonry('layout');
      msnry.layout()
    });
  }
}
